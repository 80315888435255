/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.theme.default.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.carousel.min.css
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/select2@4.0.13/dist/css/select2.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
